import React, { useState, useEffect } from "react";
import WaterDropIcon from "@mui/icons-material/WaterDrop";
import {
  FormControl,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
// Simulación de funciones de base de datos
const simulateDbQuery = (query: string, params: any[] | undefined) => {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      console.log("Simulated DB Query:", query, params);
      resolve();
    }, 500);
  });
};

export default function Pedidos() {
  const navigate = useNavigate();
  const [orders, setOrders] = useState<
    {
      id: number;
      time: string;
      client: string;
      address: string;
      distributor: string;
      ciel: number;
      ePura: number;
      blueRock: number;
      bonafont: number;
      generic: number;
      status: string;
    }[]
  >([]);
  const [newOrder, setNewOrder] = useState({
    clientNumber: "",
    distributor: "",
    address: "",
    ciel: 0,
    ePura: 0,
    blueRock: 0,
    bonafont: 0,
    generic: 0,
  } as any);

  useEffect(() => {
    const fetchOrders = async () => {
      await simulateDbQuery("SELECT * FROM orders", []);
      setOrders([
        {
          id: 1,
          time: "4:27:03 PM",
          client: "cliente_prueba",
          address: "Santa Fe CDMX",
          distributor: "Diego",
          ciel: 2,
          ePura: 2,
          blueRock: 0,
          bonafont: 0,
          generic: 0,
          status: "Cancelado",
        },
      ]);
    };
    fetchOrders();
  }, []);

  const handleLogOut = () => {
    // Simular cierre de sesión
    console.log("Cerrando sesión...");
    navigate("/");
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setNewOrder((prev: any) => ({ ...prev, [name]: value }));
  };

  const handleAddOrder = async () => {
    const newOrderEntry = {
      id: orders.length + 1,
      time: new Date().toLocaleTimeString(),
      client: newOrder.clientNumber,
      address: newOrder.address,
      distributor: newOrder.distributor,
      ciel: newOrder.ciel,
      ePura: newOrder.ePura,
      blueRock: newOrder.blueRock,
      bonafont: newOrder.bonafont,
      generic: newOrder.generic,
      status: "Pendiente",
    };

    await simulateDbQuery(
      "INSERT INTO orders (client, address, distributor, ciel, ePura, blueRock, bonafont, generic, status) VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?)",
      [
        newOrderEntry.client,
        newOrderEntry.address,
        newOrderEntry.distributor,
        newOrderEntry.ciel,
        newOrderEntry.ePura,
        newOrderEntry.blueRock,
        newOrderEntry.bonafont,
        newOrderEntry.generic,
        newOrderEntry.status,
      ]
    );

    setOrders((prev) => [...prev, newOrderEntry]);
    setNewOrder({
      clientNumber: "",
      distributor: "",
      address: "",
      ciel: 0,
      ePura: 0,
      blueRock: 0,
      bonafont: 0,
      generic: 0,
    });
  };

  const handleStatusChange = async (id: number, newStatus: string) => {
    await simulateDbQuery("UPDATE orders SET status = ? WHERE id = ?", [
      newStatus,
      id,
    ]);

    setOrders((prev) =>
      prev.map((order) =>
        order.id === id ? { ...order, status: newStatus } : order
      )
    );
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case "Pendiente":
        return "#fff9c4";
      case "En Proceso":
        return "#bbdefb";
      case "Entregado":
        return "#c8e6c9";
      case "Cancelado":
        return "#ffcdd2";
      default:
        return "#f5f5f5";
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white">
      <header className="bg-blue-600 text-white shadow-lg">
        <div className="container mx-auto px-4 py-3 flex justify-between items-center">
          <div className="flex items-center">
            <WaterDropIcon className="h-8 w-8 mr-2" />
            <h1 className="text-2xl font-bold">
              Control de Pedidos - Purificadora de Agua
            </h1>
          </div>
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={handleLogOut}
          >
            Cerrar Sesión
          </button>
        </div>
      </header>

      <main className="container mx-auto px-4 py-8">
        <h2 className="text-2xl font-bold text-center text-blue-800 mb-8">
          {new Date().toLocaleDateString("es-MX", {
            weekday: "long",
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </h2>

        <div className="grid md:grid-cols-2 gap-8 mb-8">
          <div className="bg-white shadow-md rounded-lg p-6">
            <h3 className="text-xl font-semibold mb-4 text-blue-600">
              Datos del Cliente
            </h3>
            <div className="grid gap-4">
              <div>
                <label
                  htmlFor="clientNumber"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Número de Cliente
                </label>
                <input
                  type="text"
                  id="clientNumber"
                  name="clientNumber"
                  value={newOrder.clientNumber}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div>
                <label
                  htmlFor="distributor"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Repartidor
                </label>
                <input
                  type="text"
                  id="distributor"
                  name="distributor"
                  value={newOrder.distributor}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div>
                <label
                  htmlFor="address"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Dirección
                </label>
                <input
                  type="text"
                  id="address"
                  name="address"
                  value={newOrder.address}
                  onChange={handleInputChange}
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
            </div>
          </div>

          <div className="bg-white shadow-md rounded-lg p-6">
            <h3 className="text-xl font-semibold mb-4 text-blue-600">
              Garrafones a surtir
            </h3>
            <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
              {["ciel", "ePura", "blueRock", "bonafont", "generic"].map(
                (brand) => (
                  <div key={brand}>
                    <label
                      htmlFor={brand}
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      {brand.charAt(0).toUpperCase() + brand.slice(1)}
                    </label>
                    <input
                      type="number"
                      id={brand}
                      name={brand}
                      value={newOrder[brand]}
                      onChange={handleInputChange}
                      min="0"
                      className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                )
              )}
            </div>
          </div>
        </div>

        <button
          onClick={handleAddOrder}
          className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-8"
        >
          Agregar Pedido
        </button>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="tabla de pedidos">
            <TableHead>
              <TableRow>
                <TableCell>Hora</TableCell>
                <TableCell>Cliente</TableCell>
                <TableCell>Dirección</TableCell>
                <TableCell>Repartidor</TableCell>
                <TableCell>Ciel</TableCell>
                <TableCell>ePura</TableCell>
                <TableCell>BlueRock</TableCell>
                <TableCell>Bonafont</TableCell>
                <TableCell>Genérico</TableCell>
                <TableCell>Estado</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((order) => (
                <TableRow
                  key={order.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{order.time}</TableCell>
                  <TableCell>{order.client}</TableCell>
                  <TableCell>{order.address}</TableCell>
                  <TableCell>{order.distributor}</TableCell>
                  <TableCell>{order.ciel}</TableCell>
                  <TableCell>{order.ePura}</TableCell>
                  <TableCell>{order.blueRock}</TableCell>
                  <TableCell>{order.bonafont}</TableCell>
                  <TableCell>{order.generic}</TableCell>
                  <TableCell>
                    <FormControl fullWidth>
                      <Select
                        value={order.status}
                        onChange={(e) =>
                          handleStatusChange(order.id, e.target.value)
                        }
                        sx={{ backgroundColor: getStatusColor(order.status) }}
                      >
                        <MenuItem value="Pendiente">Pendiente</MenuItem>
                        <MenuItem value="En Proceso">En Proceso</MenuItem>
                        <MenuItem value="Entregado">Entregado</MenuItem>
                        <MenuItem value="Cancelado">Cancelado</MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </main>
    </div>
  );
}
